<template>
  <div class="sale-details">
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Detalle de la venta</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a @click="returnView()" class="button button-givu">Regresar</a>
        </div>
        <div class="level-item">
        </div>
      </div>
    </nav>
    <div v-if="loading" class="loading-spinner">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div v-else>
      <div v-if="saleDetails && Object.keys(saleDetails).length > 0" class="column is-12 box">
        <div class="sale-info">
          <p><strong>Número de venta:</strong> {{ saleDetails.id }}</p>
          <p><strong>Fecha de venta:</strong> {{ formatDateTimestamp(saleDetails.createdAt) }}</p>
          <p><strong>Comprador:</strong> {{ saleDetails.userNameBuyer }}</p>
          <p><strong>Vendedor:</strong> {{ saleDetails.userNameSeller }}</p>
          <p><strong>Producto:</strong> {{ saleDetails.name }}</p>
          <p><strong>Monto:</strong> {{ formatCurrency(saleDetails.price) }}</p>
          <p><strong>Descripción de compra:</strong> {{ saleDetails.description }}</p>
          <p><strong>Estado actual: </strong> {{ saleDetails.status }}</p>
          <div class="status-update">
            <p><strong>Elegir un nuevo estado de compra:</strong></p>
            <select v-model="newLabel">
              <option value="1">PAGO</option>
              <option value="2">PEDIDO EN PROCESO</option>
              <option value="3">PEDIDO ENVIADO</option>
              <option value="4">DUDAS</option>
              <option value="5">PEDIDO ENTREGADO</option>
              <option value="6">Cancelado</option>
            </select>
            <button @click="updateStatus">Guardar y Notificar</button>
          </div>
        </div>
      </div>
      <div class="column is-12 box">
        <div class="sale-info">
          <h1 style="padding: 10px 0;">Direccion del comprador</h1>
          <p><strong>Calle:</strong> {{ saleDetails.buyerAddress.street }} {{ saleDetails.buyerAddress.street_number }}</p>
          <p v-if="saleDetails.buyerAddress.street_number_interior"><strong>Número interior:</strong> {{ saleDetails.buyerAddress.street_number_interior }}</p>
          <p><strong>Colonia:</strong> {{ saleDetails.buyerAddress.neighborhood }}</p>
          <p><strong>Municipio / Delegación:</strong> {{ saleDetails.buyerAddress.deputation }}</p>
          <p><strong>Estado:</strong> {{ saleDetails.buyerAddress.city }}</p>
          <p><strong>Código Postal:</strong> {{ saleDetails.buyerAddress.zip_code }}</p>
          <product-ubication
            v-if="saleDetails.buyerAddress.latitude"
            :lat="saleDetails.buyerAddress.latitude"
            :lng="saleDetails.buyerAddress.longitude"
          />
        </div>
      </div>
      <div v-if="saleDetails && Object.keys(saleDetails).length > 0" class="column is-12 box">
        <div class="sale-info">
          <p><strong>Descripción de compra MP:</strong> <button @click="toggleDescription">Mostrar/Ocultar</button></p>
          <div v-if="showDescription">
              <p>{{ saleDetails.description_response }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="no-details">No se encontraron detalles de la venta.</p>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import mixin from '../../mixin/validateDate'

export default {
  mixins: [mixin],
  name: 'DetalleDeLaVenta',
  components: {
    ProductUbication: () => import('@/components/Products/ProductUbication')
  },
  data () {
    return {
      saleId: null,
      saleDetails: {},
      loading: false,
      newLabel: '',
      showDescription: false
    }
  },
  methods: {
    ...mapActions(['getSaleById', 'updateSaleStatus']),
    toggleDescription () {
      this.showDescription = !this.showDescription
    },
    async fetchSaleDetails () {
      try {
        this.loading = true
        const response = await this.getSaleById(this.saleId)
        console.log('API Response:', response)
        this.saleDetails = response.payload
        this.loading = false
      } catch (error) {
        console.error('Error al obtener los detalles de la venta:', error)
        this.loading = false
      }
    },
    returnView () {
      this.$router.go(-1)
    },
    getLabelNameByCode (code) {
      const labelNames = {
        1: 'PAGO',
        2: 'PEDIDO EN PROCESO',
        3: 'PEDIDO ENVIADO',
        4: 'DUDAS',
        5: 'PEDIDO ENTREGADO',
        6: 'Cancelado'
      }
      return labelNames[code] || ''
    },
    async updateStatus () {
      try {
        this.loading = true

        if (this.saleId && this.newLabel) {
          const response = await this.updateSaleStatus({ saleId: this.saleId, newStatus: this.newLabel })
          console.log('API Response:', response)
          this.saleDetails.status = this.getLabelNameByCode(this.newLabel)
          alert('Acabas de enviar un correo y una notificación al usuario sobre su nuevo estado de compra.')
        } else {
          console.error('Error: No se ha proporcionado el identificador de venta o el nuevo estado.')
        }
        this.loading = false
      } catch (error) {
        console.error('Error al actualizar el estado de la venta:', error)
        this.loading = false
      }
    }
  },
  beforeMount () {
    this.saleId = this.$route.params.saleId
    this.fetchSaleDetails()
  }
}
</script>

<style scoped>
.sale-details {
  width: 100%;
  padding: 20px;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.loading-spinner {
  text-align: center;
  margin: 20px;
}

.sale-info p {
  margin: 10px 0;
}

.status-update {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.status-update select {
  margin: 10px;

}

.status-update button {
  background-color: #eb5965;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 10px 0;
}

.no-details {
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
}
</style>
